<template>
  <div>
    <Page id="page" :class="backgroundFilter">
      <template v-slot:content>
        <div ref="carSearchGrid" id="search-page-container">
          <Section id="search-form">
            <SearchForm
              :segmentList="getDropdowns['segment']"
              :modelList="getDropdowns['model']"
              :fuelList="getDropdowns['fuel']"
              :financeProps="financeItems"
              :transmissionList="getDropdowns['transmission']"
              :resumeSearch="handleTags"
              @onSearch="search"
              @filterCounter="filterCounter"
              usedCars
              :key="searchformKey"
            />
          </Section>
          <div v-if="!getLoadingState && used.length > 0">
            <Section id="summary">
              <FilterCarProduct
                :resultCount="getTotal"
                :listOrder="getSortList"
                @onSort="sortSearch"
              />
            </Section>
            <Section id="results" v-if="used">
              <UsedSearchVehicleResultCard
                v-for="(v, i) in used.slice(0, 5)"
                :vehicle="v"
                :key="v.vin + i + v.model + 1"
                :id="v.vin"
                @onClickResult="onClick(1 + i, v.vin)"
                usedCar
              >
              </UsedSearchVehicleResultCard>

              <CardSeparator
                v-if="used.length >= 5"
                customClass="light-bg highlight-title"
                :imgURL="
                  $t('resources.images.searchpage.separatorCard1') ||
                    require('../../../assets/images/valet-icon.svg')
                "
                :title="$t('pages.searchpage.cards.evaluation.title')"
                description=""
                :buttonLabel="$t('pages.searchpage.cards.evaluation.btnLabel')"
                buttonAction="Retake Evaluation"
                primary
              ></CardSeparator>

              <UsedSearchVehicleResultCard
                v-for="(v, i) in used.slice(5, 10)"
                :vehicle="v"
                :key="v.vin + i + v.model + 2"
                :id="v.vin"
                @onClickResult="onClick(6 + i, v.vin)"
                usedCar
              >
              </UsedSearchVehicleResultCard>

              <CardSeparator
                v-if="used.length >= 10"
                customClass="dark-bg"
                :imgURL="
                  $t('resources.images.searchpage.separatorCard2') ||
                    require('../../../assets/images/car-key.svg')
                "
                :title="$t('pages.searchpage.cards.vehicleKey.title')"
                description=""
              ></CardSeparator>

              <UsedSearchVehicleResultCard
                v-for="(v, i) in used.slice(10, 15)"
                :vehicle="v"
                :key="v.vin + i + v.model + 3"
                :id="v.vin"
                @onClickResult="onClick(11 + i, v.vin)"
                usedCar
              >
              </UsedSearchVehicleResultCard>

              <CardSeparator
                v-if="used.length >= 15"
                customClass="dark-bg warranty-card"
                :imgURL="
                  $t('resources.images.searchpage.separatorCard3') ||
                    require('../../../assets/images/car-magnifying-glass.svg')
                "
                :title="$t('pages.searchpage.cards.warranty.title')"
                :description="$t('pages.searchpage.cards.warranty.description')"
                :buttonLabel="$t('pages.searchpage.cards.warranty.btnLabel')"
                buttonAction="contactPanel"
                buttonIcon="arrowRightWhite.svg"
              ></CardSeparator>

              <UsedSearchVehicleResultCard
                :class="used.slice(15).length !== 5 ? 'test' : ''"
                v-for="(v, i) in used.slice(15)"
                :vehicle="v"
                :key="v.vin + i + v.model + 4"
                :id="v.vin"
                @onClickResult="onClick(16 + i, v.vin)"
                usedCar
              >
              </UsedSearchVehicleResultCard>

              <CardSeparator
                v-if="used.length >= 20"
                customClass="light-bg"
                :imgURL="
                  $t('resources.images.searchpage.separatorCard4') ||
                    require('../../../assets/images/auto-finance.svg')
                "
                :title="$t('pages.searchpage.cards.howItWorks.title')"
                :description="
                  $t('pages.searchpage.cards.howItWorks.description')
                "
                :buttonLabel="$t('pages.searchpage.cards.howItWorks.btnLabel')"
                buttonAction="HowItWorksPage"
                primary
              ></CardSeparator>
            </Section>

            <div
              :class="!anyModalOpened ? 'mobile-btn' : 'mobile-btn-under-panel'"
            >
              <Button
                style="height: 60px"
                type="primary"
                fill
                uppercase
                :label="
                  filterLength === 0
                    ? 'Filtros'
                    : 'Filtros (' + filterLength + ')'
                "
                filename="filter-icon.png"
                @onClick="openFilterPanel"
              />
            </div>

            <Section id="pagination">
              <Pagination used />
            </Section>
          </div>
          <div v-if="getLoadingState" class="loading">
            <Button loading :border="false"></Button>
          </div>
          <div v-if="!getLoadingState && used.length === 0">
            <div class="no-results">
              <Label
                class="no-results--title"
                color="primary"
                :uppercase="true"
              >
                {{ $t("pages.searchpage.emptyResults.title") }}
              </Label>
              <div class="no-results--info">
                <Label class="no-results--paragraph" size="small">
                  {{ $t("pages.searchpage.emptyResults.description") }}
                </Label>
              </div>

              <Button
                class="no-results--button"
                fill
                :label="$t('pages.searchpage.emptyResults.btnLabel')"
                @onClick="redirectToContactPage"
              />
            </div>

            <Section id="similar" class="similar--section">
              <div class="similar-container--section">
                <SectionHeader
                  class="similar-container--header"
                  type="primary"
                  titleSize="large"
                  :title="$t('pages.searchpage.emptyResults.similarVehicles')"
                />
                <div class="similar--container">
                  <UsedSearchVehicleResultCard
                    class="similar-card--item"
                    v-for="(v, i) in getSimilarVehicles.slice(0, 4)"
                    :vehicle="v"
                    :key="v.vin + i + v.model + 5"
                  >
                  </UsedSearchVehicleResultCard>
                </div>
              </div>
            </Section>
          </div>
          <Section id="content-block">
            <ContentBlock :content="contentBlock" html />
          </Section>
        </div>
      </template>
    </Page>
  </div>
</template>

<script>
import { Section, Label, Button } from "../../atoms";
import {
  SearchForm,
  FilterCarProduct,
  UsedSearchVehicleResultCard,
  Pagination,
  CardSeparator,
  ContentBlock
} from "../../organisms";
import { SectionHeader } from "../../molecules";
import { Page } from "../../templates";
import { mapGetters } from "vuex";
import { getPriceFormatted } from "../../../utils/currency.js";
import { PAGES } from "../../../enums/pages";
import { getDynamicRoutes } from "../../../api/dynamicSearch";
import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

const CONTACT_PAGE_URL = "https://www.hyundai.pt/contacte-nos/";

export default {
  components: {
    Button,
    Page,
    Section,
    SectionHeader,
    SearchForm,
    FilterCarProduct,
    UsedSearchVehicleResultCard,
    Pagination,
    CardSeparator,
    Label,
    ContentBlock
  },
  metaInfo() {
    return {
      title: this.metadataObj?.title,
      meta: [
        {
          name: "description",
          content: this.metadataObj?.description
        },
        {
          name: "keywords",
          content: this.metadataObj?.keywords
        },
        {
          property: "og:title",
          content: this.metadataObj?.title
        },
        {
          property: "og:description",
          content: this.metadataObj?.description
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.metadataObj?.image
        }
      ]
    };
  },
  data() {
    return {
      searchformKey: 3,
      usedDynamic: false,
      needle: "",
      TRANSMISSION_DROPDOWN: "transmission",
      financeItems: {},
      list: [
        {
          label: "Pesquisar viaturas",
          url: "#"
        },
        {
          label: "Como funciona",
          url: "#"
        },
        {
          label: "Avaliar Retoma",
          url: "#"
        },
        {
          label: "Financiamento",
          url: "#"
        }
      ],
      filtersList: [
        {
          name: "Caixa Manual",
          value: "manual"
        },
        {
          name: "Caixa Automática",
          value: "automatic"
        }
      ],
      mySearch: {},
      resumeSearch: this.handleTags,
      filterLength: 0,
      metadataObj: {
        title: this.$t("meta.search.title"),
        description: this.$t("meta.search.description"),
        keywords: this.$t("meta.search.keywords"),
        image: this.$t("meta.search.image")
      }
    };
  },
  props: {
    tags: {
      type: Array[Object],
      default: () => []
    },
    contentBlock: {
      type: String,
      default: ""
    }
  },
  methods: {
    openFilterPanel() {
      this.$store.commit("openFilterPanel");
    },
    loadFilters() {
      this.segmentItems = this.getDropdowns["segment"];
      this.fuelItems = this.getDropdowns["fuel"];
      this.financeItems = this.getFinanceDropdown;
      this.modelItems = this.getDropdowns["model"];
      this.transmissionItems = this.getDropdowns["transmission"];
    },
    getPriceFormatted,
    async prevPage() {
      // update page state
      this.$store.commit("setPage", this.getPagination?.prev);
      await this.$store.dispatch("storeAllSearchResults", {
        mySearch: this.mySearch,
        isNavigationPage: true
      });
      this.$nextTick(() => {
        this.$refs.carSearchGrid.scrollIntoView();
      });
    },
    filterCounter(filterLength) {
      this.filterLength = filterLength;
    },
    async nextPage() {
      // update page state
      this.$store.commit("setPage", this.getPagination?.next);
      await this.$store.dispatch("storeAllSearchResults", {
        mySearch: this.mySearch,
        isNavigationPage: true
      });
      this.$nextTick(() => {
        this.$refs.carSearchGrid.scrollIntoView();
      });
    },
    search(completeSearch) {
      this.mySearch = completeSearch;
      this.$store.commit("setPage", 1);
      this.$store.dispatch("storeAllSearchResults", {
        mySearch: completeSearch
      });
    },
    async onRegisterHandler(user) {
      //call vuex to register backend
      await this.$store.dispatch("storeRegisterToken", user);
      this.closeRegisterForm();
    },
    onLogoutHandler() {
      this.$store.dispatch("storeLogout");
    },
    OpenRegisterForm() {
      this.isRegisterFormOpen = true;
    },
    closeRegisterForm() {
      this.isRegisterFormOpen = false;
    },
    clickCompareVehicles() {
      this.$router.push({
        name: PAGES.COMPARATOR
      });
    },
    getDynamicTagsArray(data, type, value) {
      let dynamicTags = [];
      if (type == "needle") {
        this.needle = value;
      }
      data?.forEach(item => {
        item[type]?.forEach(item => {
          if (value.includes(item.id)) {
            item.type === this.TRANSMISSION_DROPDOWN
              ? dynamicTags.push({
                  value: item.title,
                  type: item.type,
                  text: item.title
                })
              : dynamicTags.push({
                  value: item.value,
                  type: item.type,
                  text: item.value
                });
          }
        });
      });
      return dynamicTags;
    },
    redirectToContactPage() {
      window.open(CONTACT_PAGE_URL, "_blank");
    },
    sortSearch(sort, orderBy) {
      this.$store.commit("SearchModule/filter/setOrder", orderBy);
      this.$store.commit("SearchModule/filter/setSort", sort);
      this.$store.dispatch("SearchModule/fetchUsed", {
        quickSearchItem: [],
        dynamic: false
      });
    },
    onClick(index, vin) {
      const footprintEvent = {
        page: this.getPage,
        result: index,
        vin: vin
      };
      registerEvent(FOOTPRINT_TYPES.SEARCH_RESULT_CLICK, footprintEvent);
    },
    async handleDynamicRoute() {
      this.usedDynamic = false;

      await this.$store.dispatch("SearchModule/dropdown/fetchDropdowns", {
        conditionId: 2
      });

      if (this.$route.name === PAGES.SEARCH_PAGE_CUSTOM_ROUTE)
        try {
          const currentRoute = this.$router.currentRoute.path;
          const { data } = await getDynamicRoutes(currentRoute);
          if (data.data.length > 0 && data.data.length < 2) {
            let dynamicTags = [];
            let content = "";
            let sitemap = false;

            data.data.forEach(item => {
              if (
                item.internalRoute.toLowerCase() === currentRoute.toLowerCase()
              ) {
                content = item.content;
                sitemap = item.addToSitemap;
                if (item.searchBody) {
                  dynamicTags = JSON.parse(item.searchBody).tags;
                } else {
                  for (const [key, value] of Object.entries(item.props)) {
                    if (value) {
                      dynamicTags.push.apply(
                        dynamicTags,
                        this.getDynamicTagsArray(
                          this.getAllDropdowns,
                          key,
                          value
                        )
                      );
                    }
                  }
                }

                if (item?.metaData) {
                  this.metadataObj = item?.metaData;
                }
              }
            });
            this.contentBlock = content;

            if (!sitemap) {
              const canonicalLinks = document.head.querySelectorAll("link");
              if (canonicalLinks.length)
                canonicalLinks.forEach(link => {
                  if (link.rel === "canonical") link.remove();
                });
            }

            let searchTags = [...this.tags, ...dynamicTags];
            if (this.needle)
              searchTags.push({
                value: this.needle,
                text: this.needle,
                type: "needle"
              });
            this.$store.commit("setTags", searchTags);

            this.mySearch = { tags: this.getTags, needle: this.needle };
            this.usedDynamic = true;
          } else {
            throw "Route not found!";
          }
        } catch (e) {
          console.error(e);
          this.$router.push({
            name: PAGES.ERROR
          });
        }
    }
  },
  computed: {
    ...mapGetters([
      "getSimilarVehicles",
      "getPagination",
      "getErrors",
      "getLoadingState",
      "getSortList",
      "getAllDropdowns",
      "getPage"
    ]),
    ...mapGetters({
      compareModalIsOpen: "CompareModule/isPanelOpened",
      favoriteModalIsOpen: "FavoritesModule/isPanelOpened",
      used: "SearchModule/getUsed",
      getDropdowns: "SearchModule/dropdown/getDropdowns",
      getFinanceDropdown: "SearchModule/dropdown/getFinanceDropdown",
      getTotal: "SearchModule/pagination/getResults",
      getTags: "SearchModule/filter/getTags"
    }),
    backgroundFilter() {
      return {
        // "background-filter":
        //   this.favoriteRightSidebarOpened || this.compareRightSidebarOpened
      };
    },
    anyModalOpened() {
      return this.compareModalIsOpen || this.favoriteModalIsOpen;
    },
    handleTags() {
      return this.getTags ? this.getTags : this.$route.params.tags;
    },
    handleContentBlock() {
      return this.contentBlock;
    }
  },
  async mounted() {
    this.$store.commit("spinnerModule/setLoading", true);
    await this.handleDynamicRoute();
    this.loadFilters();

    this.$store
      .dispatch("SearchModule/fetchUsed", {
        quickSearchItem: this.quickSearchFilter,
        dynamic: this.usedDynamic
      })
      .then(() => {
        this.$store.commit("spinnerModule/setLoading", false);
      });

    await this.$store.dispatch("SearchModule/dropdown/fetchDropdowns", {
      conditionId: 2
    });

    if (this.saveTags) {
      this.$store.commit("SearchModule/filter/setSaveTags", false);
    }

    if (this.fromLogin) {
      this.$store.commit("SearchModule/setOpenSaveSearch");
      this.$store.commit("SearchModule/filter/setFromLogin", false);
    }

    if (this.dropdowns) {
      Object.entries(this.dropdowns).forEach(([key, value]) => {
        this.$store.commit("SearchModule/dropdown/setDropdownParent", {
          dropdown: key,
          val: value
        });
      });
    }

    if (this.getTags.length > 0 && !this.usedDynamic) {
      this.$store.commit("SearchModule/filter/setTags", this.getTags);
      this.$store.commit("SearchModule/pagination/setCurrent", 1);
      this.$store.dispatch("SearchModule/fetchUsed", {
        quickSearchItem: [],
        dynamic: false
      });
    }

    this.$meta().refresh();
    this.usedDynamic = false;
  }
};
</script>

<style lang="css" scoped src="./SearchPageUsedCars.css"></style>
